import React, { useRef } from "react"
import Layout from "../components/common/layout"
import SEO from "../components/seo"
import ContactSection from "../components/contact/contact-section"
import { graphql, useStaticQuery } from "gatsby"

const PrivacyPage = () => {
  const contactRef = useRef()
  const data = useStaticQuery(graphql`
    query PrivacyPageQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title={"Privacidad"}
        description={data.site.siteMetadata.description}
        keywords={["privacidad", "privacy"]}
        url={"https://www.waravix.com/privacy"}
      />
      <Layout currentPage={"privacy"}>
        <ContactSection contactRef={contactRef} />
      </Layout>
    </>
  )
}

export default PrivacyPage
